import { Injectable } from "@angular/core";
import { EMPTY, Observable, Subscription, lastValueFrom, take } from "rxjs";
import { Auth, authState, User } from "@angular/fire/auth";
import { Router } from "@angular/router";

@Injectable({
	providedIn: "root",
})
export class GoogleUserService {
	public readonly googleUser$: Observable<User | null> = EMPTY;
	public subs: Subscription[] = [];

	constructor(
		public readonly auth: Auth,
		private readonly router: Router,
	) {
		this.googleUser$ = authState(this.auth);
		// eslint-disable-next-line rxjs/no-async-subscribe
		const googleSubscription = this.googleUser$.subscribe(async (result) => {
			if (result) return;

			const currentPath = window.location.pathname.toString();
			if (currentPath.includes("sign-in") || currentPath === "/") {
				return;
			}

			await this.router.navigate(["/sign-in"]);
		});

		this.subs.push(googleSubscription);
	}

	public async getIdTokenResult(force = false) {
		const currentUser = await lastValueFrom(this.googleUser$.pipe(take(1)));
		if (!currentUser) throw new Error("No user");

		return await currentUser.getIdTokenResult(force);
	}
}
